import React from "react";
import DOMPurify from "dompurify";
import { BASE_URL } from "../misc/constants";
import { purifyPhoneNumber } from "../misc/helpers";
import i18n from "../misc/i18n";
import { InstituteType } from "../types/institutes";

type InstituteInformationProps = {
  institute: InstituteType;
};
const InstituteInformation: React.FC<InstituteInformationProps> = ({
  institute,
}) => {
  const mailingAddress = institute.mailing_address || institute.visitor_address;
  return (
    <div className="row">
      <div className="col-12 col-lg-9">
        {institute.name ? (
          <h5 className="contacttabs__content-headline">{institute.name}</h5>
        ) : null}

        <div className="row">
          <div className="col-12 col-xl-6">
            {mailingAddress ? (
              <>
                {mailingAddress.name ? <p>{mailingAddress.name}</p> : null}
                <p>
                  {mailingAddress.street}
                  <br />
                  {mailingAddress.zip_code} {mailingAddress.city}
                </p>
              </>
            ) : null}
            <p>
              {institute.phone_number ? (
                <>
                  {i18n.results.tel}:{" "}
                  <a
                    href={`tel:${purifyPhoneNumber(institute.phone_number)}`}
                    title={`${institute.name || ""} - ${
                      institute.phone_number
                    } anrufen`}
                  >
                    {institute.phone_number}
                  </a>
                  <br />
                </>
              ) : null}
              {institute.fax_number ? <>Fax: {institute.fax_number}</> : null}
            </p>

            <p>
              {institute.email ? (
                <>
                  <a
                    target="_top"
                    href={`mailto:${institute.email}`}
                    title={`Eine E-Mail an ${institute.name} (${institute.email}) schreiben`}
                  >
                    {i18n.results.sendEmail}
                  </a>
                  <br />
                </>
              ) : null}

              {institute.website ? (
                <a
                  href={institute.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n.results.homepage}
                </a>
              ) : null}
            </p>
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            {institute.pob_address ? (
              <>
                <p>
                  <strong>{i18n.results.postbox}:</strong>
                </p>
                {institute.pob_address.name ? (
                  <p>{institute.pob_address.name}</p>
                ) : null}
                <p>
                  {institute.pob_address.post_office_box ||
                    institute.pob_address.street}
                  <br />
                  {institute.pob_address.zip_code} {institute.pob_address.city}
                </p>
                {institute.pob_address.note ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(institute.pob_address.note),
                    }}
                  />
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        {institute.description ? (
          <div className="row">
            <div className="col col-12 description-headlilne">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(institute.description),
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {institute.logo_uri ? (
        <div className="col-3 d-none d-lg-block">
          <div className="contacttabs__content__logo">
            <img
              src={institute.logo_uri.startsWith('http')?institute.logo_uri:BASE_URL + institute.logo_uri}
              alt={institute.name || ""}
              className="img-fluid"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InstituteInformation;
