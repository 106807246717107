import React from "react";
import { InstituteType } from "../types/institutes";
import InstituteInformation from "./InstituteInformation";

type ResultsGeneralProps = {
  data: InstituteType | null;
};
const ResultGeneral: React.FC<ResultsGeneralProps> = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <>
      <InstituteInformation institute={data} />
    </>
  );
};

export default ResultGeneral;
