import React from "react";
import "./spinner.css";

type SpinnerProps = {
  size?: number;
};

export default function Spinner({ size = 25 }: SpinnerProps) {
  return (
    <div style={{ width: size, height: size }}>
      <div className="spinner" role="status">
        <span className="sr-only">wird geladen</span>
      </div>
    </div>
  );
}
