import React, { useEffect, createContext, useState } from "react";
import { DATA_ATTRS } from "../misc/constants";

type State = {
  initialInstituteType: string | null;
  apiKey: string | null;
  apiUrl: string | null;
  basePath: string | null;
  heroImagePath: string | null;
};

const initialState = {
  initialInstituteType: null,
  apiKey: null,
  apiUrl: null,
  basePath: null,
  heroImagePath: null,
};

const SettingsContext = createContext<State>(initialState);

const SettingsProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [initialInstituteType, setinitialInstituteType] = useState<
    null | string
  >(initialState.initialInstituteType);
  const [apiKey, setApiKey] = useState<null | string>(initialState.apiKey);
  const [basePath, setBasePath] = useState<null | string>(
    initialState.basePath
  );
  const [heroImagePath, setHeroImagePath] = useState<null | string>(
    initialState.basePath
  );
  const [apiUrl, setApiUrl] = useState<null | string>(null);

  useEffect(() => {
    const el = document.getElementById("kdb-client");
    if (el) {
      const initialInstituteTypeData = el.getAttribute(
        DATA_ATTRS.initialInstituteType
      );
      setinitialInstituteType(initialInstituteTypeData);

      const apiKeyData = el.getAttribute(DATA_ATTRS.apiKey);
      setApiKey(apiKeyData);

      const basePathData = el.getAttribute(DATA_ATTRS.basePath);
      setBasePath(basePathData);

      const heroImagePath = el.getAttribute(DATA_ATTRS.heroImagePath);
      setHeroImagePath(heroImagePath);

      const apiUrl = el.getAttribute(DATA_ATTRS.apiUrl);
      if (!apiUrl)
        console.error(
          "Missing data-attribute: data-api-url. This Attribute is required!"
        );
      setApiUrl(apiUrl);
    }
  }, []);

  const value = {
    initialInstituteType,
    apiKey,
    apiUrl,
    basePath,
    heroImagePath,
  };

  if (!apiUrl) {
    return null;
  }

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
