import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";

type ContactTab = {
  id: string;
  panelId: string;
  label: string;
  content: string | React.ReactNode;
};

type ContactTabsProps = {
  tabs: Array<ContactTab>;
};

const ContactTabs: React.FC<ContactTabsProps> = ({ tabs }) => {
  const [activeTabs, setActiveTabs] = useState<string[]>([tabs[0].id]);
  const { isMobile } = useIsMobile();

  // if mobile akkordions are closed,
  // we need to check if view is desktop and set at least and max ONE tab
  useEffect(() => {
    if (!isMobile && (activeTabs.length === 0 || activeTabs.length > 1)) {
      setActiveTabs([tabs[0].id]);
    }
  }, [isMobile, tabs, activeTabs]);

  function handleTabChange(e: React.MouseEvent | null, tab: ContactTab) {
    if (e) {
      e.preventDefault();
    }
    setActiveTabs((tabIds) => [tab.id]);
  }

  function handleToggle(id: string) {
    if (activeTabs.includes(id)) {
      setActiveTabs([]);
      return false;
    } else {
      setActiveTabs((tabIds) => [...tabIds, id]);
      return true;
    }
  }

  // enable accessibility tab handling based on:
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
  function handleTabKeyDown(e: React.KeyboardEvent) {
    const currentTabIndex = tabs.findIndex((t) => t.id === activeTabs[0]);
    let potentialNextTab = null;
    // go right
    if (e.key === "ArrowRight") {
      potentialNextTab = tabs[currentTabIndex + 1];
      if (!potentialNextTab) {
        potentialNextTab = tabs[currentTabIndex - 1];
      }
    }

    // go left
    if (e.key === "ArrowLeft") {
      potentialNextTab = tabs[currentTabIndex - 1];
      if (!potentialNextTab) {
        potentialNextTab = tabs[currentTabIndex + 1];
      }
    }

    if (potentialNextTab) {
      handleTabChange(null, potentialNextTab);
      document.getElementById(`tab_button_${potentialNextTab.id}`)!.focus();
      return true;
    }

    return false;
  }

  return (
    <div className="contacttabs" role="tablist" aria-label="Institut Tabs">
      {tabs.map((tab, i) => {
        return (
          <React.Fragment key={tab.id}>
            <h4
              onClick={(e) => (!isMobile ? handleTabChange(e, tab) : null)}
              key={tab.id}
              id={`tab_button_${tab.id}`}
              role="tab"
              onKeyDown={handleTabKeyDown}
              tabIndex={activeTabs.includes(tab.id) ? 0 : -1}
              aria-selected={activeTabs.includes(tab.id)}
              aria-controls={tab.panelId}
              className="contacttabs__tab"
            >
              <span className="contacttabs__tab-label">{tab.label}</span>
              <span
                className="contacttabs__tab-state"
                title={`Minimieren/Maximieren ${tab.label}`}
                onClick={() => handleToggle(tab.id)}
              />
            </h4>
            <div
              key={`${tab.id}_content`}
              id={tab.panelId}
              role="tabpanel"
              tabIndex={activeTabs.includes(tab.id) ? 0 : -1}
              aria-labelledby={`tab_button_${tab.id}`}
              className="contacttabs__content"
              hidden={!activeTabs.includes(tab.id)}
            >
              {tab.content}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ContactTabs;
