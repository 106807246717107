import React from "react";
import { ASSETS_URL } from "../misc/constants";
import { generateGoogleMapsLink, purifyPhoneNumber } from "../misc/helpers";
import i18n from "../misc/i18n";
import { InstituteType } from "../types/institutes";
import DOMPurify from "dompurify";

type InstituteVisitorInformationProps = {
  institute: InstituteType;
};
const InstituteVisitorInformation: React.FC<InstituteVisitorInformationProps> =
  ({ institute }) => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            {institute.name ? (
              <h5 className="contacttabs__content-headline">
                {institute.name}
              </h5>
            ) : null}
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            {institute.visitor_address ? (
              <>
                <h4>{i18n.results.visitorAddress}</h4>
                <p>{institute.visitor_address.name}</p>
                <p>
                  {institute.visitor_address.street}
                  <br />
                  {institute.visitor_address.zip_code}{" "}
                  {institute.visitor_address.city}
                </p>
              </>
            ) : null}
            <p>
              {institute.phone_number ? (
                <>
                  {i18n.results.tel}:{" "}
                  <a
                    href={`tel:${purifyPhoneNumber(institute.phone_number)}`}
                    title={`${institute.name} - ${institute.phone_number} anrufen`}
                  >
                    {institute.phone_number}
                  </a>
                  <br />
                </>
              ) : null}
              {institute.fax_number ? <>Fax: {institute.fax_number}</> : null}
            </p>
            <p>
              {institute.email ? (
                <>
                  <a
                    target="_top"
                    href={`mailto:${institute.email}`}
                    title={`Eine E-Mail an ${institute.name} (${institute.email}) schreiben`}
                  >
                    {i18n.results.sendEmail}
                  </a>
                  <br />
                </>
              ) : null}
              {institute.website ? (
                <a
                  href={institute.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n.results.homepage}
                </a>
              ) : null}
            </p>
          </div>
        </div>

        {institute.visitor_address &&
        (institute.visitor_address.show_map_link ||
          institute.directions_file_uri ||
          institute.directions) ? (
          <>
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-4">
                <h4>{i18n.results.directions}</h4>
                <p>
                  {institute.visitor_address.show_map_link ? (
                    <>
                      <a
                        href={generateGoogleMapsLink(
                          institute.visitor_address.street,
                          institute.visitor_address.city,
                          institute.visitor_address.zip_code
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`Adresse von ${institute.name} in Google Maps öffnen`}
                      >
                        {i18n.results.openInMaps}
                      </a>
                      <br />
                    </>
                  ) : null}
                  {institute.directions_file_uri ? (
                    <a
                      href={ASSETS_URL + institute.directions_file_uri}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.results.downloadDirections}
                    </a>
                  ) : null}
                </p>
              </div>
            </div>
            {institute.directions ? (
              <div className="row">
                <div className="col-12">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(institute.directions),
                    }}
                  />
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

export default InstituteVisitorInformation;
