function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function generateGoogleMapsLink(
  street: string,
  city: string,
  zipCode: string
) {
  return `https://www.google.com/maps/place/${street},+${zipCode}+${city}`;
}

export function purifyPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return "";
  return phoneNumber.replace(/[^\d+]/g, "");
}

export function scrollToAnchor(elementId: string) {
  if (!elementId) return false;

  const element = document.getElementById(elementId.replace("#", ""));

  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY;
    if (inIframe()) {
      window.parent.postMessage(
        {
          scrollY: y,
          event: "scrollTo",
        },
        "*"
      );
      return true;
    }

    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  } else {
    return false;
  }
  return true;
}

export function formatToId(value: string) {
  if (!value) return "";
  return value.replace(/\W/g, "_").toLocaleLowerCase();
}
