import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { SettingsContext } from "../context/settingsContext";
import { InstituteTypeItem, InstituteType } from "../types/institutes";
import { PostCodeItem } from "../types/postCodes";

let api = axios.create();

export default function useApi() {
  const { apiKey, apiUrl } = useContext(SettingsContext);
  // add token als default
  // saves setting token for every request in queries
  api.defaults.headers = {
    ...api.defaults.headers,
    "X-AUTH-TOKEN": apiKey || process.env.REACT_APP_API_KEY,
  };

  const defApiUrl = apiUrl || process.env.REACT_APP_API_URL;
  api.defaults.baseURL = defApiUrl;

  async function fetchInstituteTypes() {
    const req: AxiosResponse<{ data: InstituteTypeItem[] }> = await api.get(
      "/institute-types"
    );
    return req.data.data;
  }

  async function fetchInstitutes(
    instituteType: number | null,
    postCodeId: number | null
  ) {
    try {
      const req: AxiosResponse<{ data: InstituteType[] }> = await api.get(
        "/v2/institutes",
        {
          params: {
            type_id: instituteType,
            zip_code_id: postCodeId,
          },
        }
      );
      return req.data.data;
    } catch (err) {
      throw err;
    }
  }

  async function fetchPostCodes(zipCode: string) {
    try {
      const req: AxiosResponse<{ data: PostCodeItem[] }> = await api.get(
        "/post-codes",
        {
          params: {
            zip_code: zipCode,
          },
        }
      );
      return req.data.data;
    } catch (err) {
      throw err;
    }
  }

  return {
    fetchInstituteTypes,
    fetchInstitutes,
    fetchPostCodes,
  };
}
