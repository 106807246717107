export const BASE_URL =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL : "";
export const ASSETS_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_ASSETS_URL
    : "";

export const DATA_ATTRS = {
  initialInstituteType: "data-initial-institute-type",
  apiKey: "data-api-key",
  apiUrl: "data-api-url",
  basePath: "data-base-path",
  heroImagePath: "data-heroimage-path",
};
