import React, { useContext } from "react";
import Results from "../../components/Results";
import Search from "../../components/Search";
import { SettingsContext } from "../../context/settingsContext";

const ResultsScreen: React.FC<{}> = () => {
  const { heroImagePath } = useContext(SettingsContext);
  return (
    <div>
      <Search withImage={Boolean(heroImagePath)} />
      <Results />
    </div>
  );
};

export default ResultsScreen;
