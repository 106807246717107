const de = {
  search: {
    headline: "Finden Sie hier Ihren Ansprechpartner",
    typeSelectTitle: "Bereich auswählen",
    plzInputTitle: "Postleitzahl (Arbeitsplatz) eingeben",
    buttonLabel: "nach Ansprechpartnern suchen",
  },
  results: {
    headline: (instituteTitle: string, plz: string) =>
      `${instituteTitle} im Postleitzahlenbereich ${plz}`,
    tabs: {
      general: "Allgemein",
      visitorDirections: "Besucheradresse & Anfahrt",
    },
    sendEmail: "E-Mail schreiben",
    homepage: "Homepage",
    tel: "Telefon",
    mobile:"Mobil", 
    directions: "Anfahrt",
    contactPartner: {
      headline:
        "Ihre persönlichen Ansprechpartner sortiert nach Zuständigkeit:",
    },
    room: "Raum",
    backToOverview: "Zurück zur Übersicht",
    downloadDirections: "Anfahrtsskizze downloaden",
    openInMaps: "Adresse in Google Maps öffnen",
    postbox: "Postfach",
    visitorAddress: "Besucheradresse",
    errorMessageFirstLine: (zipCode: string) =>
      `Die PLZ ${zipCode} konnte keinem zugewiesenen Verwaltungsbezirk zugeordnet werden oder ist ungültig.`,
    errorMessageSecondLine:
      "Bitte geben Sie die Postleitzahl Ihres Arbeitsortes ein und nicht die Betriebs-PLZ. Vielen Dank!",
    errorMessageThankYou:
      "Vielen Dank!",
  },
};

export default de;
