import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ErrorResponse } from "../../types/generic";
import { PostCodeItem } from "../../types/postCodes";
import useApi from "../useApi";

function usePostCodes(zipCode: string) {
  const { fetchPostCodes } = useApi();
  return useQuery<PostCodeItem[], AxiosError<ErrorResponse>>(
    ["post-codes", zipCode],
    async () => fetchPostCodes(zipCode),
    {
      enabled: zipCode.length > 1,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
}

export default usePostCodes;
