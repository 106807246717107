import React from "react";
import { ErrorResponseItem } from "../types/generic";

type ErrorListProps = {
  errors?: ErrorResponseItem[];
};

const ErrorList: React.FC<ErrorListProps> = ({ errors = [] }) => {
  return (
    <div className="row justify-content-center">
      {errors.map((e) => (
        <div
          key={e.property}
          className="col-6 alert alert-danger text-center"
          role="alert"
        >
          {e.message}
        </div>
      ))}
    </div>
  );
};

export default ErrorList;
