import React from "react";
import { useContext, useEffect, useState } from "react";
import Autosuggest, {
  ChangeEvent,
  SuggestionSelectedEventData,
} from "react-autosuggest";
import { useDebounce } from "use-debounce";

import { SearchContext } from "../context/searchContext";
import usePostCodes from "../hooks/queries/usePostCodes";
import { PostCodeItem } from "../types/postCodes";

import ErrorList from "./ErrorList";

type SearchInputProps = {
  tabIndex?: number;
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: PostCodeItem) => suggestion.zip_code;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion: PostCodeItem) => (
  <div data-postcode-id={suggestion.zip_code_id}>{suggestion.label}</div>
);

const autosuggestTheme = {
  container: "react-autosuggest__container",
  containerOpen: "react-autosuggest__container--open",
  input: "react-autosuggest__input",
  inputOpen: "react-autosuggest__input--open",
  inputFocused: "react-autosuggest__input--focused",
  suggestionsContainer: "react-autosuggest__suggestions-container",
  suggestionsContainerOpen: "react-autosuggest__suggestions-container--open",
  suggestionsList: "react-autosuggest__suggestions-list",
  suggestion: "react-autosuggest__suggestion",
  suggestionFirst: "react-autosuggest__suggestion--first",
  suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
  sectionContainer: "react-autosuggest__section-container",
  sectionContainerFirst: "react-autosuggest__section-container--first",
  sectionTitle: "react-autosuggest__section-title",
};

const SearchInput: React.FC<SearchInputProps> = ({ tabIndex }) => {
  const {
    state: { searchValue },
    dispatch,
  } = useContext(SearchContext);
  const [suggestions, setSuggestions] = useState<PostCodeItem[]>([]);
  const [value] = useDebounce(searchValue, 500);
  const { data: postCodes, isError, error } = usePostCodes(value);

  useEffect(() => {
    if (postCodes) {
      setSuggestions(postCodes);

      // select first entry in list as default selection
      if (postCodes.length > 0) {
        dispatch({
          type: "SET_DEFAULT_POSTCODE",
          postCode: postCodes[0].zip_code,
          postCodeId: postCodes[0].zip_code_id,
          label: postCodes[0].label,
        });
      }
    }
  }, [postCodes, dispatch]);

  function onSuggestionsFetchRequested() {
    return true;
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<PostCodeItem>
  ) {
    dispatch({
      type: "SET_SEARCHVALUE",
      searchValue: data.suggestion.zip_code,
    });

    dispatch({
      type: "SELECT_POSTCODE",
      postCode: data.suggestion.zip_code,
      postCodeId: data.suggestion.zip_code_id,
      label: data.suggestion.label,
    });
  }

  function handleChange(
    event: React.FormEvent<HTMLElement>,
    params: ChangeEvent
  ) {
    if (event.type === "change") {
      const newVal = params.newValue.replace(/[^\d]/, "");
      dispatch({
        type: "SET_SEARCHVALUE",
        searchValue: newVal,
      });
    }
  }

  const inputProps = {
    onChange: handleChange,
    value: searchValue,
    placeholder: "PLZ eingeben",
    type: "text",
    maxLength: 5,
    name: "contactsearchZip",
    id: "contactsearchZip",
    required: true,
    className: "form-control",
    tabIndex,
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        focusInputOnSuggestionClick={false}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={renderSuggestion}
        theme={autosuggestTheme}
        inputProps={inputProps}
      />
      {isError && error instanceof Error ? (
        <ErrorList errors={error?.response?.data.errors} />
      ) : null}
    </div>
  );
};
export default SearchInput;
