import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ErrorResponse } from "../../types/generic";
import { InstituteType } from "../../types/institutes";
import useApi from "../useApi";

function useInstituteTypes() {
  const { fetchInstituteTypes } = useApi();
  return useQuery(["institute-types"], fetchInstituteTypes);
}

export default function useInstitutes(
  instituteType: number | null,
  postCodeId: number | null
) {
  const { fetchInstitutes } = useApi();
  return useQuery<InstituteType[], AxiosError<ErrorResponse>>(
    ["institutes", instituteType, postCodeId],
    () => fetchInstitutes(instituteType, postCodeId),
    {
      refetchOnWindowFocus: false,
      enabled: instituteType !== null && postCodeId !== null,
    }
  );
}

export { useInstituteTypes };
