import React, { useContext, useEffect, useRef } from "react";
import { SearchContext } from "../context/searchContext";
import ContactTabs from "./ContactTabs";
import ErrorList from "./ErrorList";
import useInstitutes, {
  useInstituteTypes,
} from "../hooks/queries/useInstitutes";
import ResultGeneral from "./ResultGeneral";
import ResultsVisitorDirections from "./ResultsVisitorDirections";
import i18n from "../misc/i18n";
import ContactPartnerInformation from "./ContactPartnerInformation";
import ContactPartner from "./ContactPartner";
import { InstituteType, InstituteContactDivision } from "../types/institutes";
import { useCallback } from "react";

const Results: React.FC<{}> = () => {
  const resultBoxRef = useRef<HTMLDivElement>(null);
  const { state } = useContext(SearchContext);
  const { data: instituteTypes } = useInstituteTypes();
  const { data, isError, error } = useInstitutes(
    state.instituteType,
    state.postCodeId
  );
  const observer = useRef<IntersectionObserver | null>(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (data && headerRef.current) {
      // @ts-ignore
      headerRef.current.focus();
    }
  }, [headerRef, data]);

  // used to check for data and scroll to results
  // if results are not within the current viewport
  // uses IntersectionObserver: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    let callback = (entries: Array<IntersectionObserverEntry>, _: any) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return entry.target.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        if (observer.current) {
          return observer.current.disconnect();
        }
      });
    };
    if (observer.current == null) {
      observer.current = new IntersectionObserver(callback, options);
    }
    const target = resultBoxRef.current;
    if (target) {
      observer.current.observe(target);
    }

    return () => {
      if (observer.current) {
        return observer.current.disconnect();
      }
    };
  }, [data, resultBoxRef, state]);

  const selectedInstituteType = instituteTypes
    ? instituteTypes.find(
        (instituteType) =>
          Number(instituteType.id) === Number(state.instituteType)
      )
    : null;

  // Display contacts conditionaly based on division
  const header = useCallback(
    (index: number | null, dataItem: InstituteType | null) => {
      if (!dataItem) {
        return null;
      }

      const hasSpecificDivision = dataItem.contact_divisions.some(
        (division) => division.id === "Einheitliche-Ansprechstellen-fuer-Arbeitgeber"
      );

      const tabs = [
        {
          id: "contacttabsTab01",
          panelId: "contacttabsPanel01",
          label: i18n.results.tabs.general,
          content: (
            <div>
              <ResultGeneral data={dataItem} />
              {hasSpecificDivision &&
                dataItem.contact_divisions
                  .filter(
                    (division) =>
                      division.id === "Einheitliche-Ansprechstellen-fuer-Arbeitgeber"
                  )
                  .map((division: InstituteContactDivision) => (
                    <ContactPartnerInformation
                      key={division.id}
                      contacts={dataItem.contacts.filter((contact) =>
                        division.contact_ids.includes(contact.id)
                      )}
                      division={division} 
                      handleClick={() => {}} 
                      tabIndex={0}
                    />
                  ))}
            </div>
          ),
        },
        {
          id: "contacttabsTab02",
          panelId: "contacttabsPanel02",
          label: i18n.results.tabs.visitorDirections,
          content: <ResultsVisitorDirections data={dataItem} />,
        },
      ];

      return (
        <section
          ref={index === 0 ? resultBoxRef : null}
          className={`section section--light-grey pt-4 ${
            dataItem?.contacts.length === 0 || data?.length === 0
              ? "pb-4"
              : "pb-0"
          }`}
        >
          <div className="container">
            {index === 0 || index == null ? (
              <header className="row">
                <div className="col-12">
                  <h3
                    className="h4 font-bold mb-xl-3"
                    ref={headerRef}
                    tabIndex={4}
                  >
                    {i18n.results.headline(
                      selectedInstituteType ? selectedInstituteType.name : "",
                      state.label || state.defaultLabel || ""
                    )}
                  </h3>
                </div>
              </header>
            ) : null}
            {index !== null ? (
              <div className="row">
                <div className="col-12">
                  <ContactTabs tabs={tabs} />
                </div>
              </div>
            ) : null}

            {data?.length === 0 ? (
              <div>
                <p>
                  {i18n.results.errorMessageFirstLine(String(state.postCode))}
                  <br />
                  {selectedInstituteType && Number(selectedInstituteType.id) === 8 ? i18n.results.errorMessageThankYou : i18n.results.errorMessageSecondLine}
                </p>
              </div>
            ) : null}
          </div>
        </section>
      );
    },
    [data, selectedInstituteType, state.postCode, state.label, state.defaultLabel]
  );

  if ((isError && error instanceof Error) || !data) {
    return (
      <>
        {header(null, null)}
        <section className="section pt-1 section--light-grey">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ErrorList errors={error?.response?.data.errors} />;
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  // safety net if isError was not triggered but no data is returned
  if (data === null) return null;

  if (data.length === 0) {
    return <>{header(null, null)}</>;
  }

  return (
    <>
      {data.map((dataItem, index) => {
        return (
          <React.Fragment key={dataItem.id}>
            {header(index, dataItem)}
            {dataItem.contact_divisions.some(
              (division) => division.id !== "Einheitliche-Ansprechstellen-fuer-Arbeitgeber"
            ) && (
              <section className="section pt-1 section--light-grey">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="contacttabs__content d-block">
                        <ContactPartner institute={dataItem} /> {/* Render original component for other divisions */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Results;
