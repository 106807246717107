import { QueryClient, QueryClientProvider } from "react-query";
import { SearchProvider } from "./context/searchContext";
import { SettingsProvider } from "./context/settingsContext";
import './styles/custom-styles.css'; 


import Routes from "./Routes";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SearchProvider>
        <SettingsProvider>
          <Routes />
        </SettingsProvider>
      </SearchProvider>
    </QueryClientProvider>
  );
}

export default App;
