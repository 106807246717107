import React from "react";
import { Switch, Route, MemoryRouter as Router } from "react-router-dom";

import ResultsScreen from "./screens/ResultsScreen";
import SearchScreen from "./screens/SearchScreen";

const Routes: React.FC<{}> = () => {
  return (
    <Router>
      <Switch>
        <Route path="/results/:instituteType/:postCode">
          <ResultsScreen />
        </Route>
        <Route path="/">
          <SearchScreen />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
