import DOMPurify from "dompurify";
import React, { useCallback } from "react";
import { formatToId, purifyPhoneNumber } from "../misc/helpers";
import i18n from "../misc/i18n";
import {
  InstituteContact,
  InstituteContactDivision,
} from "../types/institutes";

type ContactPartnerInformationProps = {
  contacts: InstituteContact[];
  division: InstituteContactDivision;
  handleClick: (divisonId: string) => void;
  tabIndex: number;
};
const ContactPartnerInformation: React.FC<ContactPartnerInformationProps> = ({
  contacts,
  division,
  handleClick,
  tabIndex,
}) => {
  const getRoles = useCallback(
    (contact: InstituteContact) => {
      return contact.roles.filter((r) => r.division === division.label && r.division !== r.name);
    },
    [division]
  );

  // Check if the current division is the specific one
  const isSpecificDivision = division.id === "Einheitliche-Ansprechstellen-fuer-Arbeitgeber";


  return (
    <>
      <hr />
      <h5
        id={String(division.id)}
        tabIndex={tabIndex}
        className="contactlist__item-headline"
      >
       {isSpecificDivision ? "Ihre Ansprechpartner" : division.label}
      </h5>
      <div className="row">
        {contacts.map((contact, contactIndex) => {
          const fullName = `${contact.salutation} ${contact.firstname} ${contact.lastname}`;
          return (
            <div
              className={`col-12 col-xl-6 ${
                isSpecificDivision ? "contact-info" : ""
              }`}
              key={`cp_detail_${contact.id}`}
              aria-label={`Ansprechpartner Kontaktinformationen ${division.id} - ${fullName}`}
              id={`ansprechparter_${formatToId(division.id)}_${formatToId(
                fullName
              )}`}
            >
              <h6 className="contactlist__item-name" tabIndex={0}>
                {fullName ? <strong>{fullName}</strong> : null}
                <br />
                {getRoles(contact).map((role, i) => (
                  <span key={`${i}_${role.division}`}>
                    {role.name}
                    {i < getRoles(contact).length - 1 ? ", " : ""}
                  </span>
                  
                ))}
              </h6>
                {contact.note ? (
                    <>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(contact.note),
                        }}
                    />
                    </>
                ) : null}
              

              
              <p>
                {contact.mobile_number ? (
                    <>
                      {i18n.results.mobile}:{" "}
                      <a
                        href={`tel:${purifyPhoneNumber(contact.mobile_number)}`}
                        title={`Jetzt ${fullName} (${contact.mobile_number}) anrufen`}
                      >
                        {contact.mobile_number}
                      </a>
                      <br />
                    </>
                  ) : null}
                {contact.phone_number ? (
                  <>
                    {i18n.results.tel}:{" "}
                    <a
                      href={`tel:${purifyPhoneNumber(contact.phone_number)}`}
                      title={`Jetzt ${fullName} (${contact.phone_number}) anrufen`}
                    >
                      {contact.phone_number}
                    </a>
                    <br />
                  </>
                ) : null}
                {contact.fax_number ? (
                  <>Fax: {contact.fax_number || "-"}</>
                ) : null}
              </p>

              {contact.email ? (
                <>
                  <p>
                    <a
                      target="_top"
                      href={`mailto:${contact.email}`}
                      title={`Eine E-Mail an ${fullName} (${contact.email}) schreiben`}
                    >
                      {i18n.results.sendEmail}
                    </a>
                  </p>
                </>
              ) : null}
            </div>
          );
        })}
      </div>
      {!isSpecificDivision && (
        <div className="contactlist__item-action">
          <button
            onClick={() => handleClick("#contactlistTitle")}
            className="link link--up"
            style={{ border: 0, backgroundColor: "transparent" }}
            title="Zurück zur Übersicht"
          >
            {i18n.results.backToOverview}
          </button>
        </div>
      )}
    </>
  );
};

export default ContactPartnerInformation;
