import React from "react";
import { scrollToAnchor } from "../misc/helpers";
import { InstituteType } from "../types/institutes";
import ContactPartnerInformation from "./ContactPartnerInformation";

type ContactPartnerProps = {
  institute: InstituteType;
};
const ContactPartner: React.FC<ContactPartnerProps> = ({ institute }) => {
  function changeFocus(divisionId: string) {
    const element = document.getElementById(divisionId);
    if (element) {
      element.focus();
      return true;
    }
    return false;
  }

  function handleAnchorClick(divisionId: string) {
    if (divisionId) {
      changeFocus(divisionId);
      scrollToAnchor(divisionId);
    }
  }

  if (!institute || institute.contact_divisions.length === 0) return null;

  return (
    <>
      <h4 id="contactlistTitle" className="contactlist__title">
        Ihre persönlichen Ansprechpartner sortiert nach Zuständigkeit:
      </h4>
      <ul className="list-unstyled">
        {institute.contact_divisions.map((division) => (
          <li key={division.id} className="my-1">
            <button
              onClick={() => handleAnchorClick(division.id)}
              className="btn-link link"
              style={{ border: 0, backgroundColor: "transparent" }}
              title={division.label}
              aria-label={division.label}
            >
              {division.label}
            </button>
          </li>
        ))}
      </ul>
      {institute.contact_divisions.map((division) => {
        const contacts = institute.contacts.filter((c) =>
          division.contact_ids.includes(c.id)
        );
        return (
          <ContactPartnerInformation
            handleClick={handleAnchorClick}
            tabIndex={0}
            key={`cp_${division.id}`}
            {...{
              contacts,
              division,
            }}
          />
        );
      })}
    </>
  );
};

export default ContactPartner;