import React from "react";
import { InstituteType } from "../types/institutes";
import InstituteVisitorInformation from "./InstituteVisitorInformation";

type ResultVisitorDirectionsProps = {
  data: InstituteType | null;
};
const ResultVisitorDirections: React.FC<ResultVisitorDirectionsProps> = ({
  data,
}) => {
  if (!data) {
    return null;
  }
  return (
    <>
      <InstituteVisitorInformation institute={data} />
    </>
  );
};

export default ResultVisitorDirections;
