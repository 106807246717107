import React from "react";
import { useInstituteTypes } from "../hooks/queries/useInstitutes";

type InstitutesSelectorProps = {
  institute: null | number;
  setInstitute: (val: number) => void;
  tabIndex?: number;
};

const InstitutesSelector: React.FC<InstitutesSelectorProps> = ({
  institute,
  setInstitute,
  tabIndex,
}) => {
  const { data } = useInstituteTypes();

  function handleChange(e: React.FormEvent<HTMLSelectElement>) {
    setInstitute(Number(e.currentTarget.value));
  }

  if (data) {
    return (
      <div className="custom-select-wrapper">
        <select
          name="contactsearchType"
          id="contactsearchType"
          required
          className="custom-select"
          value={institute || undefined}
          onChange={handleChange}
          tabIndex={tabIndex}
        >
          <option>Bitte auswählen</option>
          {data.map((it) => {
            return (
              <option key={it.id} value={it.id}>
                {it.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  return null;
};
export default InstitutesSelector;
